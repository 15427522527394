@import "../../style/App.scss";

.hero {
  padding-top: 180px;
  padding-bottom: 60px;
  width: 100%;

  .wrapper {
    position: relative;
    .hero-info {
      display: flex;
      flex-direction: column;

      position: absolute;
      top: 20px;
      left: -5px;

      h1 {
        background-color: $main-color;
        margin-bottom: 25px;
        padding: 8px 18px;
      }

      .big__title {
        display: none;
      }

      .button-link:hover {
        background-color: #ffffff92;
      }

      .hero-buttons {
        .button:first-child {
          margin-bottom: 25px;
        }
      }
    }

    .hero-image {
      width: 100%;
    }
  }
}

@media screen and (max-width: 750px) {
  .hero {
    padding-top: 90px;

    .wrapper {
      padding: 0;

      .hero-info {
        position: static;
        align-items: center;
        margin-bottom: 30px;

        h1 {
          display: none;
        }

        .big__title {
          display: inline;
        }

        .hero-buttons {
          display: flex;
          flex-direction: row;

          .button:first-child {
            margin-bottom: 0;
            margin-right: 25px;
          }
        }
      }
    }
  }
}
