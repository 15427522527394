@import "../../../style/App.scss";

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  text-align: center;
  margin: 0 5%;

  .backdrop {
    background-color: $black-color;
    opacity: 0.8;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 300;
    display: block;
    cursor: pointer;
  }

  div {
    z-index: 400;
    .sucess__modal {
      margin: 5px;
      background-color: $white-color;
      z-index: 500;
      padding: 30px;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 1.5rem;
        color: #1e1e1e;
        font-weight: 800;

        &:nth-child(1) {
          font-size: 3rem;
          font-weight: 700;
          color: $main-color;
        }
        &:nth-child(2) {
          margin: 20px 0;
        }
      }

      .modal__btn {
        background-color: $main-color;
        padding: 10px 25px;
        border-radius: 10px;
        cursor: pointer;

        p {
          color: #1e1e1e;
          font-size: 1.4rem;
        }
      }
    }
  }

  div {
    z-index: 400;
    .error__modal {
      margin: 5px;
      background-color: $white-color;
      z-index: 500;
      padding: 30px;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      p {
        font-size: 1.5rem;
        color: #1e1e1e;
        font-weight: 800;

        &:nth-child(1) {
          font-size: 3rem;
          font-weight: 700;
          color: $danger-color;
          margin-bottom: 10px;
        }
        &:nth-child(2) {
          margin: 10px 0 0 0;
        }
        &:nth-child(3) {
          margin: 20px 0;
          font-size: 2rem;
          color: #1e1e1e;
          font-weight: 700;
        }
      }

      .modal__btn {
        background-color: $main-color;
        padding: 10px 25px;
        border-radius: 10px;
        cursor: pointer;

        p {
          margin: 0px;
          padding: 0px;
          color: #1e1e1e;
          font-size: 1.4rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .modal {
    div {
      .error__modal {
        padding: 20px;
        p {
          &:nth-child(3) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
