@import "../../../style/App.scss";

.button {
  .button-link {
    background-color: $main-color;
    border: 2px solid $main-color;
    padding: 8px 25px;
    cursor: pointer;
    p {
      margin-left: 10px;
      font-size: 1.4rem;
      font-weight: 800;
      text-decoration: none;
      color: $black-color;
    }
  }
  .button-link:hover {
    border: 2px solid $main-color;
    background-color: transparent;
  }
}
