@import "../../style/App.scss";

.review__box {
  width: 170px;
  // height: 220px;
  margin: 0 auto;

  .review-stars {
    width: 100%;
    justify-content: center;
    padding-left: 12px;
  }

  p {
    text-align: center;
  }
  h4 {
    text-align: center;
    color: $grey-color;
  }
}
