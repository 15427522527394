@import "../../style/App.scss";

.about__info {
  max-width: 370px;
  height: 420px;
  margin: 0 auto;
  margin-top: 60px;
  position: relative;
  border: 4px solid $light-color;
  padding: 50px 25px;
  margin-bottom: 60px;

  h4 {
    padding-bottom: 20px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 22px 40px; /* Adjust the gap between rows and columns if needed */
    // font-family: Arial, sans-serif;
  }

  .grid-item {
    text-align: left;
  }

  .border-right {
    border-right: 2px solid $main-color; /* Adjust the border thickness and color as needed */
    padding-right: 10px; /* Adjust the padding for aesthetic spacing */
  }
}
