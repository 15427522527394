@import "../../style/App.scss";

.Logo {
  #contrast-logo-text {
    h3 {
      color: $white-color;
    }
  }

  img {
    width: 60px;
  }
}

.Logo:hover {
  .logo-text {
    h3 {
      color: $main-color;
    }
  }
}

@media screen and (max-width: 720px) {
  .Logo {
    img {
      width: 50px;
    }

    .logo-text {
      h3 {
        font-size: 1.5rem;
      }
    }
  }
}
